import React, { useEffect, useState } from 'react';
import { FullScreenLoading } from 'zent';
import distributionApi from '../../../api/distribution';
// import { getSeoConfig } from './seo';
import LocalStorage from '../../../util/local-storage';
import SessionStorage from '../../../util/session-storage';
import { DISTRIBUTION_SESSIONS, DISTRIBUTION_SESSIONS_SHARD } from '../../../util/session-storage/config';
import { DISTRIBUTION_COOKIES } from '../../../util/local-storage/config';
import { DISTRIBUTION_TAG } from '../../../const/distribution';
import Style from './style';
import goodsApi from 'api/goods';

const DistributionTransfer = (props) => {
  const { initialData, userInfo } = props;
  const { referralLink = {} } = initialData;
  const [redirectUrl, setRedirectUrl] = useState('');
  const [qrCode, setQrCode] = useState(false);

  const storeDistributionCookies = (cookies, expiresAt) => {
    SessionStorage.removeSessionStorage(DISTRIBUTION_COOKIES);
    LocalStorage.remove(DISTRIBUTION_COOKIES);
    if (expiresAt > 0) {
      return LocalStorage.set(DISTRIBUTION_COOKIES, { ...cookies }, expiresAt * 24);
    }
    SessionStorage.setSessionStorage(DISTRIBUTION_SESSIONS, JSON.stringify(cookies));
  };

  const reportClick = (params) => {
    distributionApi.reportClick(params);
  };

  const getQueryString = (str, name) => {
    const reg = new RegExp(`(^|\\?|&)${name}=([^&]*)(\\s|&|$)`, 'i');
    if (reg.test(str)) {
      return unescape(RegExp.$2.replace(/\+/g, ' '));
    }
    return '';
  };

  const changeOrigin = (url) => {
    const urlOrigin = /^https?:\/\/[\w-.]+(:\d+)?/i.exec(url)?.[0];
    const browserOrigin = window.location.origin;
    if (urlOrigin !== browserOrigin) {
      url = url.replace(urlOrigin, browserOrigin);
    }
    return url;
  };

  useEffect(() => {
    (async () => {
      const { customerId } = userInfo || {};
      let { expiresAt, metafields = {}, redirectUrl: url, affiliateId = '' } = referralLink;
      metafields?.fields?.push({ key: 'affiliateId', value: affiliateId.toString() });
      url = changeOrigin(url);
      storeDistributionCookies({ cookies: metafields, expiresAt }, expiresAt);
      if (customerId) {
        const { fields = [] } = metafields || {};
        reportClick({
          customerId,
          atUuid: fields.find((item) => item?.key === 'atUuid')?.value,
          atPs: fields.find((item) => item?.key === 'atPs')?.value,
        });
      }
      const urlParams = new URLSearchParams(window.location.search);
      const isQrCode = !!(SessionStorage.getSessionStorage(DISTRIBUTION_SESSIONS_SHARD) || urlParams.get('qrCode'));
      setQrCode(isQrCode);

      const isMobile = navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad|android)/);
      if (!isMobile && url.includes('/m/')) {
        const host = window.location.origin;
        const pcLink = {
          home: host,
          goodsDetail: `${host}/products/`,
        };
        if (url.includes('/m/pages/home/index')) {
          url = pcLink.home;
        }
        if (url.includes('/m/pages/goods/detail/index')) {
          const goodsId = getQueryString(url, 'goodsId');
          const goodsHandle = (await goodsApi.getGoodsById({ goodsId }))?.handle;
          url = pcLink.goodsDetail + goodsHandle;
        }
      }
      setRedirectUrl(url);

      if (isQrCode) {
        SessionStorage.setSessionStorage(DISTRIBUTION_SESSIONS_SHARD, 1);
        const [removeQrCodeUrl, qrCodeValue] = window.location.href.split('?');
        qrCodeValue && window.location.replace(removeQrCodeUrl);
      } else {
        window.location.replace(`${url}?${DISTRIBUTION_TAG}=1`);
      }
    })();
  }, []);

  return (
    <Style>
      <FullScreenLoading loading={!qrCode} icon='circle' colorPreset='grey' />
      {qrCode && <iframe className='prev-iframe' src={`${redirectUrl}?${DISTRIBUTION_TAG}=1`} />}
    </Style>
  );
};

export default DistributionTransfer;
